import React from 'react'
import { groupByAlphabet } from '../../utils/groupByAlphabet'
const checkMarketOpening = require('../../utils/checkMarketOpening')
const dayjs = require('dayjs')

const MarketItem = ({ market }) => {
  return (
    <div
      className="w-full xs:w-6/12 md:w-4/12 mb-10 marketindex__item"
      data-cy={'marketindex-item'}>
      <p
        className="marketindex__item--name font-headline leading-tight text-sm md:text-base"
        data-cy={'market-name'}>
        {market.name}
      </p>
      <p
        className="marketindex__item--street text-sm sm:text-base"
        data-cy={'market-street'}>
        {market.address.street}
      </p>
      <p
        className="marketindex__item--city text-sm sm:text-base"
        data-cy={'market-city'}>
        {market.address.zip} {market.address.city}
      </p>
    </div>
  )
}

const Markets = ({ markets }) => {
  const alphGroups = groupByAlphabet(markets)
  return (
    <>
      {markets ? (
        Object.keys(alphGroups)
          .sort()
          .map((char, charIndex) => {
            if (alphGroups[char]['markets'].length > 0) {
              return (
                <div
                  key={`char-${charIndex}`}
                  data-cy={'marketindex-group'}
                  className="w-full flex flex-wrap items-stretch ">
                  <h3 className="marketindex__group w-full text-center mb-20 mt-40 first:mt-0">
                    {char}
                  </h3>
                  {alphGroups[char]['markets'].map((market, marketIndex) => {
                    const { aboutToOpen, afterOpeningDate } =
                      checkMarketOpening(dayjs(), market)
                    if (!aboutToOpen && !afterOpeningDate) return null

                    // show market in hof with id 60114 as opened
                    if (
                      market.status == '5' ||
                      market.status == '9' ||
                      market._id == '60114'
                    ) {
                      return (
                        <MarketItem
                          key={`market-${marketIndex}`}
                          market={market}
                        />
                      )
                    }
                  })}
                </div>
              )
            }
          })
      ) : (
        <h3>Es können derzeit keine Märkte angezeigt werden</h3>
      )}
    </>
  )
}

export default Markets
