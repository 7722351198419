import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components'
import { Seo } from '../components/base'
import { LayoutBackground } from '../components/shared'
import Markets from '../components/markets/Markets'

const MarktindexPage = ({ data, pageContext }) => {
  const { title, desc } = data?.marketIndexPage.nodes[0] || {
    title: 'Märkte von A-Z',
    desc: ''
  }
  const languages = { pageContext }
  const markets = data.markets.nodes
  const layoutBackgroundHeaderComponent = (
    <div className="mx-auto max-w-6xl mb-14 md:mb-24">
      <h1 className="mb-5 md:mb-11 text-center">{title}</h1>
      <p>{desc}</p>
    </div>
  )

  return (
    <Layout standardPaddingTopFromNavbarToMain translations={languages}>
      <Seo title={title} />
      <LayoutBackground headerComponent={layoutBackgroundHeaderComponent}>
        <section className="pt-24 md:pt-0 container xl:container">
          <Markets markets={markets} className="marketindex" />
        </section>
      </LayoutBackground>
    </Layout>
  )
}

export default MarktindexPage

export const pageQuery = graphql`
  query ($language: String! = "de_de", $country: String! = "DE") {
    markets: allSanityMarket(
      filter: { countryCode: { eq: $country }, status: { in: ["5", "9", "4"] } }
    ) {
      nodes {
        ...MarketQuery
      }
    }
    marketIndexPage: allSanityMarketIndexPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        title
        desc
        image {
          ...ImageObjectQuery
        }
      }
    }
  }
`
