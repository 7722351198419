export const groupByAlphabet = array => {
  let alphArr = []
  array.forEach(item => {
    if (!item.name) {
      console.log('missing name', item)
    } else {
      let group = item.name[0].toUpperCase()
      if (!alphArr[group]) alphArr[group] = { group, markets: [item] }
      else alphArr[group].markets.push(item)
    }
  })
  return alphArr
}
